<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="fscPefcReportCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="fscReports">{{ $t('message.fsc') }}</v-tab>
                        <v-tab key="pefcReports">{{ $t('message.pefc') }}</v-tab>
                        <v-tab key="auditList">{{ $t('message.audits') }}</v-tab>
                        <div class="d-flex flex-row align-center" style="left: 0 !important;">
                            <v-switch
                                :true-value="1"
                                :false-value="0"
                                :label="$t('message.showContractsNoInvoice')"
                                class="mt-0"
                                hide-details="auto"
                                v-model="fscPefcShowNoInvoices"
                                @change="reloadReports"
                            ></v-switch>
                        </div>
                    </v-tabs>
                    <v-tabs-items v-model="fscPefcReportCurrentTab">
                        <v-tab-item key="fsc-reports">
                            <v-card flat>
                                <FscReports :key="fscReportComponentKey"></FscReports>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="pefc-reports">
                            <v-card flat>
                                <PefcReports :key="pefcReportComponentKey"></PefcReports>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="audit-list">
                            <v-card flat>
                                <AuditList @audits-updated="reloadReports()"></AuditList>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    // import { api } from "../../../api";
    // import {textTruncate, getCurrentAppLayout} from "Helpers/helpers";
    // import moment from "moment";
    // import {mapGetters} from "vuex";
    // import {mapActions} from "vuex";
    import {mapFields} from "vuex-map-fields";
    const FscReports = () => import("Components/Appic/FscPefc/FscReports");
    const PefcReports = () => import("Components/Appic/FscPefc/PefcReports");
    const AuditList = () => import("Components/Appic/FscPefc/AuditList");

    export default {
        name: "FscPefcReport",
        components: {AuditList, FscReports, PefcReports},
        data() {
            return {
                editDialog: false,
                fscReportComponentKey: 0,
                loader: false,
                loading: {
                    save: false,
                    settings: false
                },
                pefcReportComponentKey: 0
            }
        },
        computed: {
            ...mapFields('runtime', {
                fscPefcReportCurrentTab: 'fscPefcReportCurrentTab',
                fscPefcShowNoInvoices: 'fscPefcShowNoInvoices'
            })
        },
        methods: {
            reloadReports () {
                this.fscReportComponentKey += 1
                this.pefcReportComponentKey += 1
            }
        },
        mounted () {
            // this.$title =  this.$t('message.titles.fscPefcReport')
            if(this.fscPefcReportCurrentTab == null) this.fscPefcReportCurrentTab = 0
        }
    }
</script>

<style lang="scss">

</style>
